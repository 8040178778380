<template>
  <div class="full-width">
    <v-row>
      <v-col
        class="col-1">
        <side-nav-toggle />
      </v-col>
      <v-col class="col-6">
        <v-btn
          small
          text
          outlined
          href="/#/plan"
        >
          Plan
        </v-btn>
        &nbsp;
        <v-btn
          small
          text
          outlined
          href="/#/map"
        >Map</v-btn>
        &nbsp;
        <v-btn
          small
          text
          outlined
          href="/#/holidays"
        >
          Holidays
        </v-btn>
        &nbsp;
        <v-btn
          small
          text
          outlined
          href="/#/vehicles"
        >
          Vehicles
        </v-btn>
        &nbsp;
        <v-btn
          small
          text
          outlined
          href="/#/help"
        >
          Help
        </v-btn>
        &nbsp;
        <v-btn
          small
          text
          outlined
          @click="logOut"
        >
          Log Out
        </v-btn>
      </v-col>
      <v-col
        v-if="isPlan"
        class="col-5"
      >
        <v-btn-toggle
          style="position:relative;top:-2px"
        >
          <v-btn
            height="28"
            small
            text
            outlined
            :class="{ active: dept === 'Paint' }"
            @click="setVehicleDept('Paint')"
          >
            Paint
          </v-btn>
          <v-btn
            height="28"
            small
            text
            outlined
            :class="{ active: dept === 'Thermo' }"
            @click="setVehicleDept('Thermo')"
          >
            Thermo
          </v-btn>
          <v-btn
            height="28"
            small
            text
            outlined
            :class="{ active: dept === 'Highways' }"
            @click="setVehicleDept('Highways')"
          >
            Highways
          </v-btn>
          <v-btn
            height="28"
            small
            text
            outlined
            :class="{ active: dept === 'Surveys' }"
            @click="setVehicleDept('Surveys')"
          >
            Surveys
          </v-btn>
          <v-btn
            height="28"
            small
            text
            outlined
            :class="{ active: dept === '' }"
            @click="setVehicleDept('')"
          >
            All
          </v-btn>
        </v-btn-toggle>
        <!--
          <v-btn small text outlined>Reports</v-btn>
        -->
        &nbsp;
      </v-col>
    </v-row>
    <v-row
      v-if="isPlan"
      class="mt-0"
    >
      <v-col class="col-7">
        <span style="font-size: 16px">
          Week {{ chosenWeek }} beginning: {{ chosenDate }}
        </span>
        <br />
        <v-btn-toggle
          style="position:relative;top:-3px"
        >
          <v-btn
            small
            @click="$store.commit('setCalendarType', 1)"
          >View Week</v-btn>
          <v-btn
            small
            @click="$store.commit('setCalendarType', 2)"
          >View Month</v-btn>
          <v-btn
            small
            @click="$store.commit('setCalendarType', 3)"
          >Search Jobs</v-btn>
        </v-btn-toggle>
        &nbsp;
        <v-btn
          outlined
          small
          text
          @click="goToToday">
          Today
        </v-btn>
        &nbsp;
        <v-btn
          small
          outlined
          text
          @click="fetchConvertedJobs"
        >
          Fetch Converted Jobs
        </v-btn>
      </v-col>
      <v-col class="col-3">
        <search-vehicles
          v-if="isPlan || isVehicles"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Localbase from 'localbase';
import { format, startOfWeek, getWeek } from 'date-fns';
import SearchVehicles from '@/components/Tools/SearchVehicles.vue';
import SideNavToggle from '@/components/Navigation/Controls/SideNavToggle.vue';
import axios from '../../axios';

export default {
  name: 'TopNav',
  components: {
    SearchVehicles,
    SideNavToggle,
  },
  computed: {
    chosenDate() {
      const today = this.$store.state.date;
      const firstDayOfTheWeek = startOfWeek(new Date(today));
      const chosenSunday = new Date(firstDayOfTheWeek);
      const chosenDate = chosenSunday.setDate(chosenSunday.getDate() + 1);
      const chosenFormattedDate = format(chosenDate, 'EEEE do MMMM yyy');
      return chosenFormattedDate;
    },
    chosenWeek() {
      const today = this.$store.state.date;
      const week = getWeek(new Date(today));
      return week;
    },
    isPlan() {
      return this.$route.name === 'Plan';
    },
    isVehicles() {
      return this.$route.name === 'Vehicles';
    },
    dept() {
      return this.$store.state.selectedVehicleDept;
    },
  },
  methods: {
    fetchConvertedJobs() {
      axios.get(`/pal/fetchConvertedJobs.json?token=${this.token}`);
    },
    goToToday() {
      const today = new Date();
      const date = format(today, 'yyyy-MM-dd');
      this.$store.commit('setDate', date);
    },
    setVehicleDept(dept) {
      this.$store.dispatch('setVehicleDept', dept);
    },
    setToday() {
      const time = new Date();
      const today = `${time.getFullYear()}-${(time.getMonth() + 1)}-${time.getDate()}`;
      this.$store.commit('setDate', today);
    },
    setTomorrow() {
      const time = new Date();
      const tomorrowTime = new Date(time);
      tomorrowTime.setDate(tomorrowTime.getDate() + 1);
      const tomorrow = `${tomorrowTime.getFullYear()}-${(tomorrowTime.getMonth() + 1)}-${tomorrowTime.getDate()}`;
      this.$store.commit('setDate', tomorrow);
    },
    logOut() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
  },
};
</script>

<style lang="scss">
  .full-width {
    width: 100%;
  }
  .header-container {
    max-width: none;
  }
  .active {
    background-color: #CFD8DC;
  }
</style>

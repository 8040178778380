<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :value="$store.state.drawer"
      :mobile-breakpoint="768"
      app
      v-if="showDrawer"
      >
      <img width="100%" src="./assets/riggotts_logo.png" alt="" />
      <side-nav v-if="isPlan" />
      <side-nav-map v-if="isMap" />
      <side-nav-help v-if="isHelp" />
      <side-nav-holidays v-if="isHolidays" />
      <side-nav-vehicles v-if="isVehicles" />
    </v-navigation-drawer>
    <v-app-bar
      v-if="!isLogin"
      app
      prominent
    >
      <top-nav />
    </v-app-bar>
    <v-main>
      <router-view></router-view>
      <snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from '@/components/Global/Snackbar.vue';
import SideNav from '@/components/Navigation/SideNav.vue';
import SideNavVehicles from '@/components/Navigation/SideNavVehicles.vue';
import SideNavMap from '@/components/Navigation/SideNavMap.vue';
import TopNav from '@/components/Navigation/TopNav.vue';
import SideNavHolidays from '@/components/Navigation/SideNavHolidays.vue';
import SideNavHelp from '@/components/Navigation/SideNavHelp.vue';

export default {
  name: 'App',
  components: {
    Snackbar,
    SideNav,
    SideNavHelp,
    SideNavHolidays,
    SideNavMap,
    SideNavVehicles,
    TopNav,
  },
  computed: {
    isLogin() {
      return this.$route.name === 'Login';
    },
    isPlan() {
      return this.$route.name === 'Plan';
    },
    isVehicles() {
      return this.$route.name === 'Vehicles';
    },
    isHelp() {
      return this.$route.name === 'Help';
    },
    isHolidays() {
      return this.$route.name === 'Holidays';
    },
    isMap() {
      return this.$route.name === 'Map';
    },
    showDrawer() {
      let showDrawer = true;
      if (this.$route.name === 'Login') showDrawer = false;
      return showDrawer;
    },
  },
};
</script>

<style lang="scss">
  header {
    font-size: 1.25rem!important;
  }
  .normal-select {
    -moz-appearance: auto;
    -webkit-appearance: auto;
    appearance: auto;
  }
</style>

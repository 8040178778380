import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login.vue';
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import('../views/Plan.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue'),
  },
  {
    path: '/data-tasks',
    name: 'DataTasks',
    component: () => import('../views/DataTasks.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/holidays',
    name: 'Holidays',
    component: () => import('../views/Holidays.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import('../views/Vehicles.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/order-vehicles',
    name: 'Vehicles',
    component: () => import('../views/OrderVehicles.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  next();
});

router.afterEach(() => {
  store.commit('setVehicleSearch', '');
  goTo(0, { duration: 0 });
});

export default router;
